*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

body {
    box-sizing: border-box;
}

@font-face {
    font-family: "Share Tech";
    src: url("../assets/fonts/share-tech/share-tech.otf");
}
@font-face {
    font-family: "Share Tech Mono";
    src: url("../assets/fonts/share-tech/ShareTechMono-Regular.ttf");
}
@font-face {
    font-family: "Nostromo Regular Light";
    src: url("../assets/fonts/nostromo-regular/NostromoRegular-Light.otf");
}
@font-face {
    font-family: "Nostromo Regular Medium";
    src: url("../assets/fonts/nostromo-regular/NostromoRegular-Medium.otf");
}
@font-face {
    font-family: "Nostromo Regular Bold";
    src: url("../assets/fonts/nostromo-regular/NostromoRegular-Bold.otf");
}
@font-face {
    font-family: "Nostromo Regular Black";
    src: url("../assets/fonts/nostromo-regular/NostromoRegular-Black.otf");
}
@font-face {
    font-family: "Nostromo Regular Heavy";
    src: url("../assets/fonts/nostromo-regular/NostromoRegular-Heavy.otf");
}

span {
    font-family: "Share Tech", "Roboto", "Helvetica", "Arial", "sans-serif";
}

body {
    overflow: hidden; /* Hide scrollbars */
}

/* Update `src/containers/dimensions.ts` */
html {
    font-size: 60%; /* 62.5% of 16px is 10px */
}

@media only screen and (max-width: 1922px) {
    html {
        font-size: 52%;
    }
}

@media only screen and (max-width: 1500px) {
    html {
        font-size: 50%;
    }
}

@media only screen and (max-width: 900px) {
    html {
        font-size: 44%;
    }
}

@media only screen and (max-width: 600px) {
    html {
        font-size: 39%;
    }
}

a {
    text-decoration: none;
    color: #00ffff;
}
